import { Injectable, inject, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  protected readonly _router = inject(Router);
  protected readonly _activatedRoute = inject(ActivatedRoute);
  protected readonly _translate = inject(TranslateService);

  currentLang$ = signal(this._translate.currentLang);

  constructor() {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        const root = this._router.routerState.snapshot.root;
        this.currentLang$.set(root.firstChild?.params['lang'] || this.currentLang$());
      });
  }

  changeLanguage(lang: string): void {
    const url = this._router.url;
    const urlSegments = url.split('/');

    urlSegments.shift(); // Remove empty string
    urlSegments.shift(); // Remove the old language code segment

    // Construct new route with the selected language
    const newRoute = '/' + lang + '/' + urlSegments.join('/');

    this._router
      .navigate([newRoute])
      .then(() => {
        this.currentLang$.set(lang);
        this._translate.use(lang);
      });
  }

  getCurrentLangCode(): string {
    return this.currentLang$() === 'en' ? 'en-US' : 'fr-CA';
  }

  getLangField(field?: any, lang?: string): any {
    return field ? field[lang || this.getCurrentLangCode()] : field;
  }
}
